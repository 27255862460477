$(document).ready(function(){
  $('.contact-us h3').click(function(){
    if($(this).parent().hasClass('open')){
      $(this).parent().removeClass('open');
    }else{
      $(this).parent().addClass('open');
    }
  });

  $('.contact-us .contact-info').click(function(){
    if($(this).parent().hasClass('open')){
      $(this).parent().removeClass('open');
    }else{
      $(this).parent().addClass('open');
    }
  });

  if($('.left').data('bg')){
    var bg = $('.left').data('bg');
    //document.styleSheets[0].addRule('.left::after','background: url('+bg+')');
    /*
    document.styleSheets[0].insertRule('.left::after { background: url('+bg+') }', 0);
    document.styleSheets[0].rules[0].style.backgroundImage= "url("+bg+")";
    */
    var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);
    if(isIE11){
      setTimeout(function(){ $('.left').css({opacity:0.95}); }, 100);
      // setTimeout(function(){ $('.left').css({opacity:1}); }, 1000);
      // setTimeout(function(){ $('.left').css({opacity:0.95}); }, 2000);
      setTimeout(function(){ $('.left').css({opacity:1}); }, 3000);
      
    }
    //*/
  }
  
  /*
  $('.person-grid').mouseenter(function(e){
    if($( window ).width() > 485){
      $('body').addClass('phover');
      document.getElementById("logo").src="/img/roots_black.svg";
    }
  }).mouseleave(function(e){
    $('body').removeClass('phover');
    document.getElementById("logo").src="/img/roots_purple.svg";
  });
  */

  $('.m-nav').click(function(){
    if($('#menu').hasClass('open') === true){
      $('#menu').removeClass('open');
      $('body').removeClass('mopen');
      if($('body').hasClass('home') || $('body').hasClass('contact-page')){
          document.getElementById("logo").src="/img/roots_white.svg";
      }else{
        document.getElementById("logo").src="/img/roots_purple.svg";
      }
    }else{
      $('#menu').addClass('open');
      $('body').addClass('mopen');
      $('html,body').animate({scrollTop:0},0);
      document.getElementById("logo").src="/img/roots_black.svg";
    }
  });
});
